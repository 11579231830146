import React from "react";
import styled from "styled-components";

import breakpoints from "../../utils/breakpoints";
import { Mega } from "../typography";
import { grid } from "../../utils";

export default function Hero({ heading, body, subline, show_border, stack_on_tablet }) {
  const Container = styled.div`
    padding: 0 var(--grid-padding);
  `;

  const Content = styled.div`
    padding: ${body === undefined ? '24.3rem 0 0' : '24.3rem 0 8.9rem'};
    border-bottom: ${show_border === false ? "0rem solid #2b4463" : "0.1rem solid #2b4463"};

    @media ${breakpoints.tabletSm} {
      display: ${stack_on_tablet ? 'block' : 'flex'};
      align-items: flex-end;
      padding: 24.1rem 0 7.79rem;
    }

    @media ${breakpoints.tablet} {
      display: ${subline ? 'block' : 'flex'};
      align-items: flex-end;
      padding: 36.6rem 0 7.79rem;
    }

    @media ${breakpoints.desktop} {
      padding: 36.6rem 0 7.79rem;
    }
  `;

  const Heading = styled.div`
    margin-bottom: 3rem;

    @media ${breakpoints.tabletSm} {
      margin-bottom: ${stack_on_tablet ? '3rem' : '0'};
    }

    @media ${breakpoints.tablet} {
      //max-width: ${grid(6.69,true)}; // Single pixel hack to create correct wrapping
      margin-bottom: 0;
    }

    @media ${breakpoints.desktop} {
      //width: ${grid(6.2,true)}; // Single pixel hack to create correct wrapping
    }

    ${Mega} {
      font-size: 6.4rem;
      line-height: 1;

      @media ${breakpoints.tabletSm} {
        font-size: 9rem;
      }
    }
  `;

  const Body = styled.div`
    font-size: 1.6rem;
    padding: 0 0 0 0;
    p{
      margin:0 0 0 0;
    }

    @media ${breakpoints.tabletSm} {
      padding: ${stack_on_tablet ? '0 20rem 0 0' : '0'};
      margin: ${stack_on_tablet ? '0' : '0 0 1rem auto'};
      max-width: ${stack_on_tablet ? '100%' : grid(5.6,true)};
    }

    @media ${breakpoints.tablet} {
      font-size: 1.9rem;
      flex: 1;
      padding: 0 0 0 6rem;
      margin:0 0 1rem auto;
      max-width: ${grid(7.4,true)};
    }
    @media ${breakpoints.desktop} {
      max-width: ${grid(5.4,true)};
    }
  `;

  const Subline = styled.div`
    a {
      text-decoration: underline;
    }
    margin-top: 2.25rem;
  `;

  return (
    <Container>
      <Content>
        <Heading>
          <Mega>{heading}</Mega>
        </Heading>
        <Body dangerouslySetInnerHTML={{ __html: body }}></Body>
      </Content>
      {subline && <Subline><div dangerouslySetInnerHTML={{ __html: subline }}/></Subline>}
    </Container>
  );
}
