import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { withPrefix } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import breakpoints from "../../utils/breakpoints";
import { grid } from "../../utils/index";

const Container = styled.div`
  padding: 5.05rem var(--grid-padding) 2.5rem;

  @media ${breakpoints.tabletSm} {
    padding: 6.2rem var(--grid-padding) 13rem;
  }

  @media ${breakpoints.tablet} {
    padding: 6.8rem var(--grid-padding) 15rem;
  }

  @media ${breakpoints.desktop} {
    padding: 8.8rem var(--grid-padding) 15rem;
  }
`;

const GridItem = styled.div`
  margin-bottom: 7.5rem;

  @media ${breakpoints.tabletSm} {
    margin-bottom: 7rem;
    width: 33.333%;
    flex: none;
    padding-right: var(--grid-gutter);
  }

  @media ${breakpoints.desktop} {
    margin-bottom: 10rem;
  }
`;

const GridItemImage = styled(GatsbyImage)`
  display: block;
  margin-bottom: 2rem;
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
`;

const GridItemText = styled.div`
  line-height: 1.3125;

  @media ${breakpoints.tabletSm} {
    font-size: 0.875em;
    line-height: 1.2857142857;
  }

  @media ${breakpoints.desktop} {
    font-size: 0.8421052632em;
    line-height: 1.3125;
  }

  p {
    margin-bottom: 0;
  }

  p:first-child {
    font-weight: bold;
  }
`;

const RowsContainer = styled.div`
  position: relative;

  @media ${breakpoints.tablet} {
    display: flex;
    align-items: flex-start;
  }

  & + & {
    margin-top: 6.5rem;

    @media ${breakpoints.tabletSm} {
      margin-top: 6rem;
    }

    @media ${breakpoints.desktop} {
      margin-top: 5.3rem;
    }
  }
`;

const Rows = styled.div`
  width: 100%;
  overflow: hidden;

  @media ${breakpoints.tablet} {
    width: ${grid(9)};
  }
`;

const Row = styled.div`
  @media ${breakpoints.tabletSm} {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + var(--grid-gutter));
  }
`;

const RowHeading = styled.div`
  font-size: 2.2rem;
  line-height: 1.3181818182;
  margin-bottom: 3.5rem;

  @media ${breakpoints.tabletSm} {
    font-size: 2.1rem;
    line-height: 1.1904761905;
    margin-bottom: 4.8rem;
  }

  @media ${breakpoints.tablet} {
    flex: 1;
  }

  @media ${breakpoints.desktop} {
    font-size: 2.4rem;
    line-height: 1.25;
  }

  &.is-sticky {
    @media ${breakpoints.tablet} {
      position: sticky;
      top: 18rem;
    }
  }

  p {
    margin-bottom: 0;

    @media ${breakpoints.tabletSm} {
      max-width: 10em;
    }

    &.mobile-only {
      @media ${breakpoints.tablet} {
        display: none;
      }
    }
  }

  select {
    display: block;
    width: 100%;
    border-bottom: solid #2b4463;
    border-width: 0 0 0.1rem;
    font-size: 1.6rem;
    line-height: 1.25;
    background-color: transparent;
    appearance: none;
    font-weight: 300;
    height: 3.3rem;
    margin-top: 1.8rem;
    background: transparent url(${withPrefix("caret.svg")}) no-repeat right
      center;
    background-size: 1rem 0.5rem;
    letter-spacing: 0.01em;
    margin-bottom: 5rem;
    color: #224466;
    border-radius: 0;
    padding: 0;
    outline: none;

    @media ${breakpoints.tabletSm} {
      font-size: 1.4rem;
      line-height: 1.4285714286;
      height: 4.9rem;
      margin-top: 3.3rem;
      background-size: 1.3rem 0.6rem;
    }

    @media ${breakpoints.tablet} {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  ul {
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;

    @media ${breakpoints.tablet} {
      display: block;
      line-height: 1.619047619;
    }

    @media ${breakpoints.desktop} {
      line-height: 1.6666666667;
    }

    button {
      position: relative;
      color: #224466;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: calc(100% + 0.1rem);
        left: 0;
        width: 100%;
        height: 0.15rem;
        background-color: #2b4463;
        opacity: 0;
        transition: opacity 0.5s var(--ease-soft);
      }

      &:hover:after {
        opacity: 1;
      }
    }

    .is-active:after {
      opacity: 1;
      transform: none;
    }
  }
`;

const TeamMember = ({ data, ...props }) => {
  const image = getImage(data.image.imageFile)
  return (
    <GridItem {...props}>
      <GridItemImage placeholder="blurred" image={image} alt={data.image.alt} />
      <GridItemText>
        <p>{data.title}</p>
        {data.content && parse(data.content)}
      </GridItemText>
    </GridItem>
  );
};

export default function Team({ grouped, terms }) {
  return (
    <Container>
      {terms.sort((a, b) => a.index_order - b.index_order).map((term, i) => {
        if (!grouped[term.id]) return null
        const rows = []
        grouped[term.id].forEach(p => {
          if (!rows[Math.max(rows.length - 1, 0)]) {
            rows.push([])
          }
          if (p.new_row_after) {
            rows[Math.max(rows.length - 1, 0)].push(p)
            return rows.push([])
          }
          rows[Math.max(rows.length - 1, 0)].push(p)
        })
        return (
        <RowsContainer>
          <RowHeading>
            <p>{term.title}</p>
          </RowHeading>
          <Rows>
            {
              rows.map((r, i) => (
                <Row key={i} >
                  {r.map((p) => (
                    <TeamMember data={p} key={p.id} className="is-selected is-active"/>
                  ))}
                </Row>
              ))
            }
          </Rows>
        </RowsContainer>
      )})}
      {/* {data.team.categories.map((category, i) => {
        const allTeamMembers = [...data.team.teamMembers];
        const filteredTeamMembers = allTeamMembers.filter(
          member => member.categories.indexOf(category.id) > -1
        );
        const sorted = filteredTeamMembers.sort((a, b) => {
          if (a.categorySortOrder < b.categorySortOrder) {
            return -1;
          }
          if (a.categorySortOrder > b.categorySortOrder) {
            return 1;
          }
          return 0;
        });

        return (
          <RowsContainer key={i}>
            <RowHeading>
              <p>{category.name}</p>
            </RowHeading>
            <Rows>
              <Row>
                {sorted.map((teamMember, i) => {
                  return <TeamMember key={i} data={teamMember} />;
                })}
              </Row>
            </Rows>
          </RowsContainer>
        );
      })} */}
    </Container>
  );
}
