import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";

import breakpoints from "../../utils/breakpoints";

const Container = styled.div`
  padding: 0 var(--grid-padding) 5rem;

  @media ${breakpoints.tabletSm} {
    padding: 0 var(--grid-padding) 3.8rem;
  }

  @media ${breakpoints.desktop} {
    padding: 0 var(--grid-padding) 5rem;
  }

  p {
    margin: 0;
    max-width: 40em;
    font-size: 1.2rem;

    @media ${breakpoints.tablet} {
      font-size: 1.4rem;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      opacity: 0.75;
    }
  }
`;

export default ({ body }) => {
  return (
    <Container>
      <p>{parse(body || '')}</p>
    </Container>
  );
};
