import React from "react";
import { graphql } from 'gatsby'

import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Team from "../components/team";
import Disclaimer from "../components/disclaimer";

export default ({ path, data: { cms: { entries, page_team, terms, seo } } }) => {

  const grouped = {}
  entries.data.forEach((person) => {
    if (!person?.team?.id) return
    if (grouped[person.team.id]) {
      return grouped[person.team.id].push(person)
    }
    grouped[person.team.id] = [person]
  });

  return (
    <Layout theme="dark">
      <SEO
        title={seo.title}
        description={seo.og_description}
        meta={[
          {
            "property": "og:image",
            "content": "/images/og-updated.png"
          }
        ]}
      />
      <Hero heading={page_team.headline} body={page_team.hero_body} />
      <Team grouped={grouped} terms={terms.data} />
      <Disclaimer body={page_team.disclaimer} />
    </Layout>
  );
};


export const query = graphql`
query team {
  cms {
    entries(collection: "team", sort: "order") {
      data {
        id
        ... on cms_Entry_Team_Team {
          id
          team {
            id
            slug
            title
          }
          content
          title
          new_row_after
          image {
            id
            ... on cms_Asset_Assets {
              id
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            path
          }
        }
      }
    }
    page_team: entry(id: "b6add5cb-fe6e-4f49-bf8d-140818f05174") {
      ... on cms_Entry_Pages_PageTeam {
        id
        disclaimer
        title
        headline
        hero_body
      }
    }
    seo: SeoMeta(page_id: "b6add5cb-fe6e-4f49-bf8d-140818f05174") {
      og_description
      og_title
      title
    }
    terms(taxonomy: "team") {
      data {
        id
        title
        ... on cms_Term_Team_Team {
          index_order
        }
      }
    }
  }
}
`